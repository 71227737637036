import React, { useEffect, useState } from 'react'

import {
  AlignItems,
  Box,
  ButtonColorVariant,
  ButtonSize,
  Display,
  Heading3,
  Heading6,
  Icon,
  Modal,
  ModalBody,
  ModalButton,
  ModalClose,
  ModalFooter,
  ModalHeader,
  Space,
  useModal,
} from '@gousto-internal/citrus-react'
import classnames from 'classnames'
import moment from 'moment'

import { trackSubscriptionSettingsChange } from '../../tracking'
import { CalendarDeliverDay, useSubscriptionCalendar } from '../hooks/useSubscriptionCalendar'
import { DeliveryDay } from '../hooks/useSubscriptionToDeliveryDays'

import css from './Calendar.css'

export const SUBSCRIPTION_CALENDAR_MODAL_NAME = 'SUBSCRIPTION-CALENDAR-MODAL'

type Props = {
  deliveryDays: DeliveryDay[] | null
  preSelectedDay: string | null
  updateSelectedDayFromCalendar: (day: CalendarDeliverDay) => void
  showModal: boolean
}

export const CalendarModal = ({
  deliveryDays,
  preSelectedDay,
  updateSelectedDayFromCalendar,
  showModal,
}: Props) => {
  const initialSelectedDay =
    (deliveryDays?.find((day) => day.date === preSelectedDay) as unknown as CalendarDeliverDay) ||
    null
  const [selectedDay, setSelectedDay] = useState<CalendarDeliverDay | null>(initialSelectedDay)
  const { getCalendarWeekDays, getCalendarDays, getInitialDate, getLastDate } =
    useSubscriptionCalendar(deliveryDays)
  const { closeCurrentModal } = useModal()

  const initialDate = getInitialDate()
  const lastDate = getLastDate()

  const initialFormattedDate = moment(initialDate).format('D')
  const lastFormattedDate = moment(lastDate).format('D')

  const initialMonth =
    new Date(initialDate).getMonth() === new Date(lastDate).getMonth()
      ? ''
      : ` ${moment(initialDate).format('MMM')}`

  useEffect(() => {
    if (preSelectedDay) {
      setSelectedDay(initialSelectedDay)
    }
  }, [preSelectedDay, initialSelectedDay])

  const handleDayClick = (day: CalendarDeliverDay) => () => {
    setSelectedDay(day)
  }

  const handleSaveClick = () => {
    updateSelectedDayFromCalendar(selectedDay as CalendarDeliverDay)
    trackSubscriptionSettingsChange({
      settingName: 'reactivate_delivery_date',
      action: 'update',
    })({
      previous_date: preSelectedDay,
      updated_date: selectedDay?.date,
    })
    closeCurrentModal()
  }

  return (
    <Modal isOpen={showModal} name={SUBSCRIPTION_CALENDAR_MODAL_NAME} onAfterClose={() => {}}>
      <ModalClose onClose={closeCurrentModal} />
      <ModalHeader>
        <Heading3>Choose next delivery date</Heading3>
      </ModalHeader>

      <ModalBody>
        <Box paddingBottom={3} display={Display.Flex} alignItems={AlignItems.Center}>
          <Icon name="calendar" />
          <Space direction="horizontal" size={2} />
          <Heading6>{`${initialFormattedDate}${initialMonth} - ${lastFormattedDate} ${moment(
            lastDate,
          ).format('MMM')}`}</Heading6>
        </Box>
        <table className={css.calendarTable}>
          <thead className={css.calendarTableHead}>
            <tr>
              {getCalendarWeekDays().map((weekDay, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <th className={css.headerCell} key={`${weekDay}${index}`}>
                  {weekDay}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {getCalendarDays().map((week) => (
              // eslint-disable-next-line react/jsx-key
              <tr>
                {week.map((day, index) =>
                  !day ? (
                    // eslint-disable-next-line react/no-array-index-key
                    <td className={css.tableCell} key={`empty-day-${index}`} />
                  ) : (
                    <td className={css.tableCell} key={day.id}>
                      <div className={css.squareContainer}>
                        <button
                          type="button"
                          onClick={handleDayClick(day)}
                          className={classnames(css.day, {
                            [css.disabled]: !day.activeForSubscribersOneOff,
                            [css.selectedDay]: selectedDay?.id === day.id,
                          })}
                          disabled={!day.activeForSubscribersOneOff}
                        >
                          <span className={css.dayNumber}>{moment(day.date).format('D')}</span>
                        </button>
                      </div>
                    </td>
                  ),
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </ModalBody>

      <ModalFooter>
        <ModalButton
          colorVariant={ButtonColorVariant.Primary}
          size={ButtonSize.Large}
          onClick={handleSaveClick}
        >
          {`Select ${moment(selectedDay?.date).format('dddd')} (starting ${moment(
            selectedDay?.date,
          ).format('Do MMM')})`}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}
