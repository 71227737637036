import React, { useEffect } from 'react'

import {
  Display,
  Box,
  FlexDirection,
  JustifyContent,
  AlignItems,
  Color,
  Button,
  Divider,
  BorderStyle,
  Heading3,
  ButtonVariant,
  ButtonColorVariant,
  Space,
  Join,
  withCitrusTheme,
  useTheme,
  Visible,
} from '@gousto-internal/citrus-react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { browserHistory } from 'react-router'

import { getMenuCutoffUntil } from 'actions/basket'
import { boxSummaryDeliveryDaysLoad, boxSummaryDeliverySlotChosen } from 'actions/boxSummary'
import { DeliveryAddress } from 'routes/DeliveryDetails/DeliveryAddress'
import { DeliveryAddressModal } from 'routes/DeliveryDetails/DeliveryAddressModal'
import { DeliveryBoxSize } from 'routes/DeliveryDetails/DeliveryBoxSize'
import { DeliveryBoxSizeModal } from 'routes/DeliveryDetails/DeliveryBoxSizeModal'
import Loading from 'routes/Menu/Loading'
import { DatePickerContainer } from 'routes/Menu/components/BoxSummary/DeliverySlot/DatePicker'
import { DeliverySupportingText } from 'routes/Menu/components/BoxSummary/DeliverySlot/DeliverySupportingText'
import { Slots } from 'routes/Menu/components/BoxSummary/DeliverySlot/types'
import { getDisabledSlotDates } from 'routes/Menu/selectors/boxSummary'
import { getIsAuthenticated } from 'selectors/auth'
import { getSubscriptionState } from 'selectors/subscription'
import { getLoadingStateForOrder, getUserOrders } from 'selectors/user'
import { getDeliveryDaysAndSlots, getTempDeliveryOptions } from 'utils/deliverySlotHelper'

import { DeliveryDateSlot } from './DeliveryDateSlot'

import css from './DeliveryDetails.css'

export const DeliveryDetails = () => {
  const { theme } = useTheme()
  const dispatch = useDispatch()
  const store = useStore()
  const isAuthenticated = useSelector(getIsAuthenticated)
  const disabledSlots = useSelector(getDisabledSlotDates)
  const userOrders = useSelector(getUserOrders)
  const subscriptionState = useSelector(getSubscriptionState)
  const isSubscriptionInactive = subscriptionState === 'inactive'
  const { deliveryDays, tempDate, tempSlotId, tempOrderId } = useSelector(getTempDeliveryOptions)
  const doesDateHaveDisabledSlots = disabledSlots
    .map((disabledSlotId: string) => disabledSlotId.slice(0, 10))
    .includes(tempDate)

  const isUserOrdersLoading = useSelector(getLoadingStateForOrder)

  useEffect(() => {
    const fetchData = async () => {
      const menuCutoffUntil = await getMenuCutoffUntil(store.getState)
      dispatch(boxSummaryDeliveryDaysLoad(undefined, menuCutoffUntil))
    }

    fetchData()
  }, [dispatch, store.getState])

  const handleContinueClick = () => {
    if (tempDate && tempSlotId) {
      dispatch(boxSummaryDeliverySlotChosen({ date: tempDate, slotId: tempSlotId }))
    }
    if (tempOrderId) {
      browserHistory.push(`/menu/${tempOrderId}`)
    } else {
      browserHistory.push('/menu')
    }
  }

  const helperProps = {
    disabledSlots,
    isAuthenticated,
    tempDate,
    userOrders,
    tempSlotId,
    deliveryDaysProps: deliveryDays,
  }

  const {
    slots,
    deliveryDays: deliveryDaysForDatePicker,
    hasEmptyOrders,
    hasFullOrders,
    hasActiveSlotsForSelectedDate,
  } = getDeliveryDaysAndSlots(tempDate, helperProps)

  const shouldHideEditButton = userOrders.find((order: { get: (arg0: string) => string | any[] }) =>
    order.get('deliveryDate').includes(tempDate),
  )

  const isLoading = isUserOrdersLoading || !deliveryDays || !slots

  const breakpointFromSmall = `@media (max-width: ${theme.tokens.breakpoints[0]})`

  const DeliverDetailsBox = withCitrusTheme(Box, () => ({
    [breakpointFromSmall]: {
      flexDirection: 'column',
    },
  }))

  const tempSlotLabel = (slots as Slots)[tempDate]?.find((slot) => slot.value === tempSlotId)?.label

  return isLoading ? (
    <div className={css.loadingContainer} data-testid="delivery-details-loading">
      <Loading loading={isLoading} />
    </div>
  ) : (
    <Box alignItems={AlignItems.Center} display={Display.Flex} flexDirection={FlexDirection.Column}>
      <Box
        bg={Color.White}
        borderColor={Color.NeutralGrey_100}
        borderStyle={BorderStyle.Solid}
        borderTopWidth={0}
        borderWidth={0.5}
        width={['100%', '100%', '80%', '60%']}
        paddingH={[2, 6, 6, 6]}
      >
        <Box alignItems={AlignItems.Center} display={Display.Flex} paddingV={4}>
          <Join with={<Space direction="horizontal" size={1} />}>
            <Button
              aria-label="Back"
              colorVariant={ButtonColorVariant.Tertiary}
              data-testid="back-button"
              icon="arrow_back"
              onClick={() => browserHistory.goBack()}
              variant={ButtonVariant.Icon}
            />
            <Heading3 fontWeight={700}>Delivery details</Heading3>
          </Join>
        </Box>
        <Box minHeight="70vh" paddingH={2} paddingV={4}>
          <DeliverDetailsBox display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
            <Box flexGrow={1}>
              <DeliveryDateSlot slotLabel={tempSlotLabel ?? ''} tempDate={tempDate} />
              <Visible display={['none', 'block']}>
                <DeliveryBoxSize />
                <DeliveryAddress shouldHideEditButton={shouldHideEditButton} />
              </Visible>
            </Box>
            <Space direction="horizontal" size={10} />
            <Box width={['100%', '50%']} maxWidth={['100%', '400px']}>
              <DatePickerContainer
                slots={slots}
                slotId={tempSlotId}
                deliveryDays={deliveryDaysForDatePicker}
                tempOrderId={tempOrderId}
                tempSlotId={tempSlotId}
                tempDate={tempDate}
                subLabelClassName=""
              />
              <DeliverySupportingText
                hasEmptyOrders={hasEmptyOrders}
                hasFullOrders={hasFullOrders}
                doesDateHaveDisabledSlots={
                  doesDateHaveDisabledSlots && isAuthenticated && isSubscriptionInactive
                }
                showWarning={false}
                tempDate={tempDate}
              />
            </Box>
            <Visible display={['block', 'none']}>
              <DeliveryBoxSize />
              <DeliveryAddress shouldHideEditButton={shouldHideEditButton} />
            </Visible>
          </DeliverDetailsBox>
        </Box>
        <Divider />
        <Box display={Display.Flex} justifyContent={JustifyContent.Center} paddingV={3}>
          <Button
            disabled={!hasActiveSlotsForSelectedDate}
            onClick={handleContinueClick}
            maxWidth="400px"
            width="60%"
          >
            Choose recipes
          </Button>
        </Box>
      </Box>
      <DeliveryBoxSizeModal />
      <DeliveryAddressModal />
    </Box>
  )
}
