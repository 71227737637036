import React from 'react'

import {
  AlignItems,
  Body1,
  Box,
  Color,
  Display,
  Heading5,
  Icon,
  IconVariant,
  JustifyContent,
  Space,
  Text,
  useModal,
} from '@gousto-internal/citrus-react'
import Immutable from 'immutable'
import { useSelector } from 'react-redux'

import { ShippingAddress } from '@library/api-user'

import { DELIVERY_ADDRESS_MODAL_NAME } from 'routes/DeliveryDetails/constants'
import { getChosenAddress } from 'selectors/basket'

type DeliveryAddressProps = {
  shouldHideEditButton: boolean
}

const DeliveryAddress = ({ shouldHideEditButton }: DeliveryAddressProps) => {
  const { openModal } = useModal()
  const chosenAddress: Immutable.Map<keyof ShippingAddress, unknown> = useSelector(getChosenAddress)

  return (
    <Box paddingBottom={[2, 0]}>
      <Box display="flex" justifyContent={JustifyContent.SpaceBetween} width="100%">
        <Heading5>Delivery address</Heading5>
        {!shouldHideEditButton && (
          <Box
            display={Display.Flex}
            alignItems={AlignItems.Center}
            role="button"
            aria-label="Edit delivery address"
            onClick={() => openModal(DELIVERY_ADDRESS_MODAL_NAME)}
            data-testid="address-edit-button"
          >
            <Icon name="edit" variant={IconVariant.Actionable} size={5} />
            <Space size={1} direction="horizontal" />
            <Text size={1} color={Color.Secondary_400}>
              Edit
            </Text>
          </Box>
        )}
      </Box>
      <Body1 fontWeight={700}>{chosenAddress.get('name')}</Body1>
      <Body1>{chosenAddress.get('line1')}</Body1>
      <Body1>{chosenAddress.get('line2')}</Body1>
      <Body1>{chosenAddress.get('line3')}</Body1>
      <Body1>
        {chosenAddress.get('town')}, {chosenAddress.get('postcode')}
      </Body1>
    </Box>
  )
}

export { DeliveryAddress }
