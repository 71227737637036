import moment from 'moment'
import { createSelector } from 'reselect'

import { actionTypes } from 'actions/actionTypes'
import { getRecipes, getMenuRecipeIds } from 'selectors/root'

export const getMenuLoadingErrorMessage = (state) => state.menu.get('menuLoadingErrorMessage')
export const getRecipeCount = (state) => getRecipes(state).size

export const getCurrentMenuRecipes = createSelector(
  [getRecipes, getMenuRecipeIds],
  (allRecipes, currentMenuIds) =>
    currentMenuIds &&
    currentMenuIds
      .map((recipeId) => allRecipes.get(recipeId) || null)
      .filter((recipe) => recipe !== null),
)

export const getActiveMenuIdForOrderDate = createSelector(
  [(state, props) => props.cutoffDate, (state, props) => props.menus],
  (date, menuServiceData) => {
    if (!menuServiceData) {
      return undefined
    }

    const activeMenu = menuServiceData.find(
      (menu) => moment(date) <= moment(menu.attributes.ends_at), // ends_at is the last cutoff date for the menu
    )

    if (!activeMenu) {
      return undefined
    }
    const menuId = activeMenu.id

    return menuId
  },
)

export const isMenuLoading = createSelector(
  [
    (state) => state.boxSummaryShow.get('show'),
    (state) => state.menuBrowseCTAShow,
    (state) => state.pending.get(actionTypes.MENU_FETCH_DATA, false),
    (state) => state.pending.get(actionTypes.OPTIMIZELY_ROLLOUT_LOADING, false),
  ],
  (boxSummaryShow, menuBrowseCTAShow, menuLoading, isOptimizelyLoading) => {
    const overlayShow = boxSummaryShow || menuBrowseCTAShow

    return (menuLoading || isOptimizelyLoading) && !overlayShow
  },
)

export const getHasClosedPropositionTile = (state) =>
  state.menu.get('hasClosedPropositionTile', false)

export const getHasProspectNotSeenWizard = createSelector(
  [
    (state) => state.signup && state.signup.get('isInWizardFunnel'),
    (state) => state.auth && state.auth.get('isAuthenticated'),
  ],
  (isInWizardFunnel, isAuthenticated) => !isInWizardFunnel && !isAuthenticated,
)
