import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Link from 'Link'
import { H1 } from 'Page/Header'
import { PromoModalWrapper as PromoModal } from 'PromoModal'
import Svg from 'Svg'
import { client } from 'config/routes'
import { useIsRafPersonalisationEnabled } from 'hooks/useIsRafPersonalisationEnabled'
import { useLocation } from 'routes/Menu/domains/collections/internal/useLocation'
import { useDispatch } from 'react-redux'
import actions from 'actions'
import css from '../Header.css'

/**
 * The innerComponent prop has been added for the purpose of implementing the
 * Checkout - Box Tangibility experiment. https://gousto.atlassian.net/browse/TG-8493
 * It is used to render the CheckoutUrgency component in the header for
 * small screens.
 */
export const SimpleHeader = ({
  serverError,
  className,
  homeUrl,
  title,
  showWhiteHeader,
  innerComponent,
}) => {
  const { pathname } = useLocation()
  const isRafPersonalisationEnabled = useIsRafPersonalisationEnabled()
  const dispatch = useDispatch()

  const isPromoModalDisabled =
    removeTrailingSlashes(pathname) === client.raf && isRafPersonalisationEnabled
  const isMenuPath = pathname === client.menu

  const onLoginClick = (event) => {
    event.stopPropagation()
    dispatch(actions.loginVisibilityChange(true))
  }

  return (
    <span id={serverError ? 'mobileMenu' : null}>
      <a className={className} href={serverError ? '#' : null} />
      <header className={css.header}>
        <div>
          <div className={css.container}>
            <div
              className={classnames(css.mainBar, {
                [css.menuHeader]: showWhiteHeader,
              })}
            >
              <div className={css.mainContent}>
                <Link to={homeUrl} className={css.logoLink} clientRouted>
                  <span>
                    {showWhiteHeader ? (
                      <Svg fileName="gousto-logo-red" className={css.logoDesktop} />
                    ) : (
                      <Svg fileName="gousto_logo" className={css.logoDesktop} />
                    )}
                  </span>
                </Link>
                {title ? <H1 className={css.mobileTitle}>{title}</H1> : null}
                {innerComponent}
                <button
                  type="button"
                  className={classnames(css.btn, {
                    [css.experimentBtn]: isMenuPath
                  })}
                  data-testing="loginButton"
                  onClick={onLoginClick}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {!isPromoModalDisabled && <PromoModal />}
    </span>
  )
}

const removeTrailingSlashes = (pathname) => pathname.replace(/\/+$/, '')

SimpleHeader.propTypes = {
  serverError: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  homeUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  showWhiteHeader: PropTypes.bool,
  innerComponent: PropTypes.node,
}
SimpleHeader.defaultProps = {
  title: '',
  showWhiteHeader: false,
  innerComponent: null,
}
