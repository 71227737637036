import { createSelector } from 'reselect'

import { ImmutableMap } from './ImmutableMap'

export const getPromoStore = (state: ReduxState) => state.promoStore

export const createGetPromoStoreEntry = (promoCode: string) =>
  createSelector(getPromoStore, (promoStore) => promoStore.get(promoCode))

interface ReduxState {
  promoCurrent: string
  promoStore: ImmutableMap<{
    [key in string]: PromoStore
  }>
  promoAgeVerified: boolean
  promoModalVisible: boolean
}

/**
 * Part of application store that contains all the information used within signup domain.
 *
 * Note: do not nest ImmutableMap inside another ImmutableMap - `getIn` won't work; `getIn` will work fine with
 * nested objects instead.
 */
export interface PromoStore {
  code: string
  details: ImmutableMap<PromoDetails>
  codeData: ImmutableMap<CodeData>
  addGiftOrderRules: Array<any>
  hasAgeRestricted: boolean
  justApplied: boolean
}

interface PromoDetails {
  'discount-whole-order-percent': string
  'apply-credit-to-originator': string
}

interface CodeData {
  code: string
  campaign: Campaign
}

interface Campaign {
  enabled: boolean
  landingDetails1: string
  landingDetails2: string
  landingDetails3: string
  isForExisting: boolean
  modalTitle: string
  boostCampaignId: number | null
  rawModalText: string
  expiryInterval: string
  landingUrl: string
  isBoost: boolean
  modalText: string
  id: number
  mediaId: number | null
}
