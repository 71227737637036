import { useDecision } from '@optimizely/react-sdk'

import { useAuth } from 'routes/Menu/domains/auth'

type AAATestKey = 'c' | 't1' | 't2'

export function useGetAAATestEnabled(): [boolean, AAATestKey] {
  const { isAuthenticated } = useAuth()
  const [decision] = useDecision('beetroots_ofx_aaa_test')

  const variationKey = (decision.variationKey as AAATestKey) || ''

  const isEnabled = ['t1', 't2'].includes(variationKey) && !isAuthenticated

  return [isEnabled, variationKey]
}
