import React, { useCallback, useContext, useEffect, useState } from 'react'

import {
  AlignItems,
  Body1,
  Body2,
  Bold,
  Box,
  Button,
  ButtonColorVariant,
  Color,
  Display,
  FlexDirection,
  FontFamily,
  Heading1,
  Heading6,
  Icon,
  Join,
  JustifyContent,
  Link,
  Space,
  useModal,
} from '@gousto-internal/citrus-react'
import moment from 'moment'

import { endpoint } from '@library/endpoint'

import { portionSizesConfig } from 'config/portionSizes'
import routes from 'config/routes'
import { fetch } from 'utils/fetch'

import {
  ChangeFrequencyModal,
  SUBSCRIPTION_CHANGE_FREQUENCY_MODAL_NAME,
} from '../ActiveSubscription/sections/YourSubscriptionDetails/Frequency/ChangeFrequencyModal'
import {
  ModalBoxSize,
  SUBSCRIPTION_CHANGE_BOXES_MODAL_NAME,
} from '../ActiveSubscription/sections/YourSubscriptionDetails/ModalBoxSize/ModalBoxSize'
import { SubscriptionContext } from '../context'
import { actionTypes } from '../context/reducers'
import { getSelectedBoxSize } from '../context/selectors/box'
import {
  getCurrentUserDeliveryTariffId,
  getCurrentUserId,
  getCurrentUserPostcode,
} from '../context/selectors/currentUser'
import { getCurrentDeliverySlot, getDeliveryFrequency } from '../context/selectors/deliveries'
import { getSubscriptionPortionOptions } from '../context/selectors/subscriptionOptions'
import { frequencyMapping } from '../enum/frequency'
import { useUpdateSubscription } from '../hooks/useUpdateSubscription'
import { trackSubscriptionSettingsChange } from '../tracking'
import { generateNextDeliveryFrequencyString } from './generateNextDeliveryFrequencyString'
import { CalendarDeliverDay } from './hooks/useSubscriptionCalendar'
import { useSubscriptionToDeliveryDays } from './hooks/useSubscriptionToDeliveryDays'
import { CalendarModal, SUBSCRIPTION_CALENDAR_MODAL_NAME } from './modals/CalendarModal'

type FrequencyKey = keyof typeof frequencyMapping
type Props = {
  accessToken: string
  onBackClick: () => void
  onSubmitCompleted: () => void
}

type SubscriptionResponse = {
  data: {
    userId: string
    subscription: {
      origin: string
      deliverySlotStartTime: string
      interval: number
      deliverySlotEndTime: string
      status: string
      createdAt: string
      numPortions: 2 | 4
      updatedBy: string
      authUserId: string
      deliverySlotDay: number
      boxType: 'gourmet' | 'vegetarian'
      updatedAt: string
      userId: string
      numRecipes: 2 | 3 | 4
      versionId: string
      intervalUnit: 'weeks' | 'months'
      lastChangeType: string
    }
  }
}

export const Resubscribe = ({ accessToken, onBackClick, onSubmitCompleted }: Props) => {
  const { state, dispatch } = useContext<any>(SubscriptionContext)
  const { openModal, closeCurrentModal } = useModal()

  const postcode = getCurrentUserPostcode(state)
  const deliveryTariffId = getCurrentUserDeliveryTariffId(state)
  const userId = getCurrentUserId(state)
  const chosenBoxSize = getSelectedBoxSize(state)
  const currentDeliveryFrequency = getDeliveryFrequency(state) as FrequencyKey
  const boxSizesOptions = getSubscriptionPortionOptions(state)
  const {
    id: slotId,
    defaultDay,
    deliveryStartTime,
    deliveryEndTime,
  } = getCurrentDeliverySlot(state)

  const [, , subscriptionToDays] = useSubscriptionToDeliveryDays({
    slotId,
    accessToken,
    cutOffDateTimeFrom: moment().startOf('day').toISOString(),
    cutOffDateTimeUntil: moment().startOf('day').add(21, 'days').toISOString(),
    deliveryTariffId,
    postcode,
  })

  const [shouldUpdateSubscription, setShouldUpdateSubscription] = useState(false)
  const [selectedDeliveryDayFromCalendar, setSelectedDeliveryDayFromCalendar] =
    useState<CalendarDeliverDay | null>(null)
  const [boxSize, setBoxSize] = useState<number>(chosenBoxSize)
  const [boxFrequency, setBoxFrequency] = useState<FrequencyKey>(currentDeliveryFrequency)
  const [isActivatingSubscription, setIsActivatingSubscription] = useState<boolean>(false)
  const [isErrorPanelVisible, setIsErrorPanelVisible] = useState<boolean>(false)

  const [updateLoading, updateResponse, updateError] = useUpdateSubscription({
    accessToken,
    data: {
      numPortions: boxSize,
      interval: boxFrequency,
      deliverySlotDay: selectedDeliveryDayFromCalendar?.defaultDay ?? defaultDay,
      deliverySlotStartTime: deliveryStartTime,
      deliverySlotEndTime: deliveryEndTime,
    },
    trigger: {
      shouldRequest: shouldUpdateSubscription,
      setShouldRequest: setShouldUpdateSubscription,
    },
    settingName: 'update_subscription',
  })

  const deliveryDateCopy = !selectedDeliveryDayFromCalendar
    ? subscriptionToDays.deliveryDate
    : selectedDeliveryDayFromCalendar.date

  const reactivateSubscriptionUrl = `${endpoint('subscriptioncommand')}/subscriptions/${userId}${
    routes.subscriptionCommand.activate
  }`

  const onNextDeliveryClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    openModal(SUBSCRIPTION_CALENDAR_MODAL_NAME)
    trackSubscriptionSettingsChange({ settingName: 'reactivate_delivery_date', action: 'edit' })({
      previous_date: deliveryDateCopy,
    })
  }

  const onBoxSizeClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    openModal(SUBSCRIPTION_CHANGE_BOXES_MODAL_NAME)
    trackSubscriptionSettingsChange({ settingName: 'reactivate_box_size', action: 'edit' })({
      previous_box_size: boxSize,
    })
  }

  const onFrequencyClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    openModal(SUBSCRIPTION_CHANGE_FREQUENCY_MODAL_NAME)
    trackSubscriptionSettingsChange({ settingName: 'reactivate_box_frequency', action: 'edit' })({
      previous_frequency: boxFrequency,
    })
  }

  const activateSubscription = useCallback(() => {
    if (isActivatingSubscription && (updateResponse as any)?.status === 'ok') {
      fetch(accessToken, reactivateSubscriptionUrl, { deliveryDate: deliveryDateCopy }, 'POST')
        .then((response: SubscriptionResponse) => {
          dispatch({
            type: actionTypes.SUBSCRIPTION_STATUS_UPDATE_RECEIVED,
            data: response.data,
          })

          trackSubscriptionSettingsChange({
            settingName: 'reactivate_subscription',
            action: 'complete',
          })({
            delivery_date: deliveryDateCopy,
            frequency: boxSize,
            box_size: boxFrequency,
          })

          onSubmitCompleted()
        })
        .catch(() => {
          setIsErrorPanelVisible(true)
        })
        .finally(() => {
          setIsActivatingSubscription(false)
        })
    }
  }, [
    isActivatingSubscription,
    updateResponse,
    accessToken,
    reactivateSubscriptionUrl,
    deliveryDateCopy,
    dispatch,
    onSubmitCompleted,
    boxSize,
    boxFrequency,
  ])

  useEffect(() => {
    if (!updateLoading) {
      setIsActivatingSubscription(false)
    }
  }, [updateLoading])

  useEffect(() => {
    // Update subscription was successful, attempt to reactivate for given delivery date
    activateSubscription()
  }, [activateSubscription])

  const onConfirmClick = () => {
    setShouldUpdateSubscription(true)
    setIsActivatingSubscription(true)
  }

  const updateSelectedDayFromCalendar = (day: CalendarDeliverDay) => {
    setSelectedDeliveryDayFromCalendar(day)
  }

  const handleFrequencySubmit = (selectedFrequency: string) => {
    setBoxFrequency(Number(selectedFrequency) as FrequencyKey)
  }

  const handleBoxSizeSubmit = (selectedBoxSize: number) => {
    setBoxSize(selectedBoxSize)

    dispatch({
      type: actionTypes.UPDATE_SELECTED_BOX_SIZE,
      data: { numPortions: selectedBoxSize },
    })

    closeCurrentModal()
  }

  const nextDeliveryFrequencyString = generateNextDeliveryFrequencyString(
    boxFrequency,
    !selectedDeliveryDayFromCalendar
      ? subscriptionToDays.deliveryDate
      : selectedDeliveryDayFromCalendar.date,
  )

  const shouldRenderErrorPanel = updateError || isErrorPanelVisible

  return (
    <>
      <ModalBoxSize
        isModalOpen={false}
        currentBoxSize={Number(boxSize)}
        boxSizesOptions={boxSizesOptions}
        portionSizesConfig={portionSizesConfig}
        onBoxSizeChange={handleBoxSizeSubmit}
        onModalClose={closeCurrentModal}
      />
      <ChangeFrequencyModal
        isModalOpen={false}
        currentDeliveryFrequency={String(boxFrequency)}
        onFrequencySubmit={handleFrequencySubmit}
      />
      <CalendarModal
        deliveryDays={subscriptionToDays.data}
        preSelectedDay={subscriptionToDays.deliveryDate}
        updateSelectedDayFromCalendar={updateSelectedDayFromCalendar}
        showModal={false}
      />
      <Box display={Display.Flex} justifyContent={JustifyContent.Center} paddingV={6}>
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          width={['100%', '60%']}
          bg={Color.White}
          paddingH={[6]}
          paddingV={[6]}
        >
          <Join with={<Space size={5} />}>
            <Heading1 color={Color.ColdGrey_900} size={[9]}>
              Confirm subscription details
            </Heading1>
            <Box
              display={Display.Block}
              bg={Color.Informative_50}
              borderColor={Color.Informative_200}
              width="100%"
              paddingV={3}
              paddingH={3}
              borderRadius={1.5}
              data-testid="info-panel"
            >
              <Heading6>
                <Bold>Your subscription settings</Bold>
              </Heading6>
              <Body2>{`Tell us how you'd like your ongoing deliveries to be scheduled`}</Body2>
            </Box>
            {shouldRenderErrorPanel && (
              <Box
                display={Display.Block}
                bg={Color.Error_50}
                borderColor={Color.Error_900}
                width="100%"
                paddingV={3}
                paddingH={3}
                borderRadius={1.5}
                data-testid="error-panel"
              >
                <Body2>
                  <Bold>Oops! Something went wrong...</Bold>
                </Body2>
                <Body2>
                  We&apos;re really sorry, but it seems there&apos;s an unexpected issue on our end:
                  we&apos;re currently unable to update your subscription.
                </Body2>
              </Box>
            )}
          </Join>
          <Space size={4} />
          <Join with={<Space size={4} />}>
            <Box>
              <Join with={<Space size={4} />}>
                <Box display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
                  <Box width={['2.5rem']}>
                    <Icon name="calendar" />
                  </Box>
                  <Box width={['100%']}>
                    <Body1 fontFamily={FontFamily.Bold} color={Color.ColdGrey_800}>
                      Next Delivery
                    </Body1>
                    <Body2>
                      <span>
                        <Bold color={Color.ColdGrey_800}>
                          {moment(String(deliveryDateCopy)).format('dddd Do MMM')}
                        </Bold>{' '}
                        {nextDeliveryFrequencyString}
                      </span>
                    </Body2>
                  </Box>
                  <Box
                    width={['3rem']}
                    display={Display.Flex}
                    alignItems={AlignItems.Baseline}
                    justifyContent={JustifyContent.FlexEnd}
                  >
                    <Link
                      href="#next-delivery"
                      onClick={onNextDeliveryClick}
                      data-testid="nextDeliveryEditButton"
                    >
                      Edit
                    </Link>
                  </Box>
                </Box>
                <Box display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
                  <Box width={['2.5rem']}>
                    <Icon name="serving_size" />
                  </Box>
                  <Box width={['100%']}>
                    <Body1 fontFamily={FontFamily.Bold} color={Color.ColdGrey_800}>
                      Box size
                    </Body1>
                    <Body2>{`For ${boxSize} people`}</Body2>
                  </Box>
                  <Box
                    width={['3rem']}
                    display={Display.Flex}
                    alignItems={AlignItems.Baseline}
                    justifyContent={JustifyContent.FlexEnd}
                  >
                    <Link href="#box-size" onClick={onBoxSizeClick} data-testid="boxSizeEditButton">
                      Edit
                    </Link>
                  </Box>
                </Box>
                <Box display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
                  <Box width={['2.5rem']}>
                    <Icon name="frequency" />
                  </Box>
                  <Box width={['100%']}>
                    <Body1 fontFamily={FontFamily.Bold} color={Color.ColdGrey_800}>
                      Frequency
                    </Body1>
                    <Body2>{frequencyMapping[boxFrequency]}</Body2>
                  </Box>
                  <Box
                    width={['3rem']}
                    display={Display.Flex}
                    alignItems={AlignItems.Baseline}
                    justifyContent={JustifyContent.FlexEnd}
                  >
                    <Link
                      href="#frequency"
                      onClick={onFrequencyClick}
                      data-testid="frequencyEditButton"
                    >
                      Edit
                    </Link>
                  </Box>
                </Box>
              </Join>
            </Box>
            <Box gap={2} display={Display.Flex}>
              <Button
                style={{
                  flex: 'auto',
                }}
                colorVariant={ButtonColorVariant.Secondary}
                data-testid="backButton"
                onClick={onBackClick}
              >
                Back
              </Button>
              <Button
                style={{
                  flex: 'auto',
                }}
                disabled={isActivatingSubscription}
                colorVariant={ButtonColorVariant.Primary}
                data-testid="confirmButton"
                onClick={onConfirmClick}
              >
                Confirm
              </Button>
            </Box>
          </Join>
        </Box>
      </Box>
    </>
  )
}
