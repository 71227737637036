import React from 'react'
import { getIsAuthenticated } from 'selectors/auth'
import { getIsInWizardFunnel } from 'selectors/signup'
import { Header } from 'Header'
import { useSelector } from 'react-redux'
import { useDoubleDeckerNav } from 'hooks/useDoubleDeckerNav'
import { getHasProspectNotSeenWizard } from 'routes/Menu/selectors/menu'
import { useIsSkipTheWizardEnabled } from 'hooks/useIsSkipTheWizardEnabled'
import { client } from 'config/routes'
import { useLocation } from 'routes/Menu/domains/collections/internal/useLocation'
import { getBrowserType } from 'selectors/browser'

export const MenuHeader = () => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const isInWizardFunnel = useSelector(getIsInWizardFunnel)
  const hasProspectNotSeenWizard = useSelector(getHasProspectNotSeenWizard)
  const isSkipTheWizardEnabled = useIsSkipTheWizardEnabled()
  const { pathname } = useLocation()
  const isMobileDevice = useSelector(getBrowserType) === 'mobile'
  const isMenuPath = pathname === client.menu
  const simpleHeaderForMenu = (isInWizardFunnel && !isAuthenticated) || (hasProspectNotSeenWizard || isSkipTheWizardEnabled)
  const simpleHeader = (isInWizardFunnel && !isAuthenticated) || isSkipTheWizardEnabled
  const isSimpleHeader = (isMenuPath && !isMobileDevice) ? simpleHeaderForMenu : simpleHeader
  const doubleDeckerExperimentEnabled = useDoubleDeckerNav()

  return <Header simple={isSimpleHeader} doubleDeckerExperimentEnabled={doubleDeckerExperimentEnabled} hasLoginModal />
}
