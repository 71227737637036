import { RootStateOrAny } from 'react-redux'
import { Dispatch } from 'redux'

import { legacyVerifyAge } from 'apis/legacy'
import { fetchPromo } from 'apis/promos'
import { getIsAuthenticated } from 'selectors/auth'
import { getIsGoustoOnDemandEnabled } from 'selectors/features'
import { PromoStore } from 'selectors/promoStoreSelectors'

import { actionTypes } from './actionTypes'
import { basketPromoCodeChange } from './basket'
import { menuLoadBoxPrices } from './menu'
import { productsLoadProductsById } from './products'
import statusActions from './status'
import { trackUTMAndPromoCode } from './tracking'
import { clickCloseGodFailureHomepage, discountPopupDisplayed } from './trackingKeys'
import userActions from './user'

type GetState = () => RootStateOrAny

const { pending, error } = statusActions

const promoReceive = (promo: PromoStore) => ({
  type: actionTypes.PROMO_RECEIVE,
  promo,
})

const promoStoreSaveError = (code: string, errorText: string) => ({
  type: actionTypes.PROMO_STORE_SAVE_ERROR,
  code,
  errorText,
})

export const promoGet = (code: string) => async (dispatch: Dispatch<any>, getState: GetState) => {
  dispatch(pending(actionTypes.PROMO_GET, true))
  dispatch(error(actionTypes.PROMO_GET, null))
  const accessToken: string = getState().auth.get('accessToken')
  let promo
  let errorText

  try {
    const { data } = await fetchPromo(accessToken, code)
    promo = data as any
  } catch (e: any) {
    errorText = e.message
    dispatch(error(actionTypes.PROMO_GET, errorText))
  }

  try {
    if (Boolean(promo?.codeData?.campaign?.enabled) === false) {
      errorText = 'not-exist'
      dispatch(error(actionTypes.PROMO_GET, errorText))
    }
  } catch (e) {
    errorText = 'other-error'
  }

  if (promo) {
    promo.hasAgeRestricted = false
    promo.justApplied = false
  }
  const isAuthenticated = getState().auth.get('isAuthenticated')

  if (promo && promo.addGiftOrderRules) {
    const productIds = promo.addGiftOrderRules
      .filter((rule: any) => rule.type === 'Product')
      .map((rule: any) => rule.id)

    await dispatch(productsLoadProductsById(productIds) as any)

    const state = getState()
    const ageRestricted = productIds
      .map((id: string) => state.products.get(id))
      .filter((product: any) => product.get('ageRestricted'))

    const needsUserData =
      ageRestricted.length > 0 && !state.user.get('id', false) && isAuthenticated

    promo.hasAgeRestricted = Boolean(ageRestricted.length)

    if (needsUserData) {
      await dispatch(userActions.userLoadData() as any)
    }
  }

  if (isAuthenticated) {
    if (promo?.hasAgeRestricted) {
      if (getState().user.get('ageVerified', false)) {
        try {
          await dispatch(userActions.userPromoApplyCode(code) as any)
          promo.justApplied = true
        } catch (e) {
          throw new Error('Promo cannot be applied')
        }
      }
    } else {
      try {
        await dispatch(userActions.userPromoApplyCode(code) as any)
        promo.justApplied = true
      } catch (e) {
        throw new Error('Promo cannot be applied')
      }
    }
  }

  dispatch(pending(actionTypes.PROMO_GET, false))
  if (!errorText) {
    dispatch(promoReceive(promo))
  } else {
    dispatch(promoStoreSaveError(code, errorText))
  }
}

const promoCurrentSet = (code: string) => ({
  type: actionTypes.PROMO_SET,
  code,
})

export const promoChange =
  (code: string) => async (dispatch: Dispatch<any>, getState: GetState) => {
    const state = getState()
    if (getIsGoustoOnDemandEnabled(state) && !getIsAuthenticated(state)) {
      dispatch(basketPromoCodeChange(code))
      await dispatch(menuLoadBoxPrices())
    }

    if (!state.promoStore.get(code, null)) {
      await dispatch(promoGet(code))
    }

    if (!state.error.get(actionTypes.PROMO_GET)) {
      dispatch(promoCurrentSet(code))
    }
  }

export const promoClear = () => ({
  type: actionTypes.PROMO_SET,
  code: '',
})

export const promoToggleModalVisibility = (visible: boolean) => (dispatch: Dispatch<any>) => {
  if (visible) {
    dispatch(trackUTMAndPromoCode(discountPopupDisplayed))
  }

  dispatch({
    type: actionTypes.PROMO_MODAL_VISIBILITY_CHANGE,
    visible,
  })
}

export const promoCloseModal = () => (dispatch: Dispatch<any>) => {
  dispatch(promoToggleModalVisibility(false))
  setTimeout(() => {
    dispatch(promoClear())
    dispatch(error(actionTypes.PROMO_APPLY, ''))
    dispatch(error(actionTypes.PROMO_GET, ''))
  }, 750)
}

export const promoApply = () => async (dispatch: Dispatch<any>, getState: GetState) => {
  const state = getState()

  if (!state.pending.get(actionTypes.PROMO_APPLY, false)) {
    dispatch(pending(actionTypes.PROMO_APPLY, true))

    const code = state.promoCurrent
    const hasAgeRestricted = state.promoStore.getIn([code, 'hasAgeRestricted'], false)
    const isAuthenticated = state.auth.get('isAuthenticated')

    try {
      if (isAuthenticated) {
        await dispatch(userActions.userPromoApplyCode(code))
      } else {
        dispatch(basketPromoCodeChange(code))

        if (hasAgeRestricted) {
          await legacyVerifyAge()
        }
      }

      await dispatch(menuLoadBoxPrices())
    } catch (e: any) {
      dispatch(error(actionTypes.PROMO_APPLY, e.message))
      throw e
    } finally {
      dispatch(pending(actionTypes.PROMO_APPLY, false))
    }

    dispatch(promoCloseModal())
  }
}

export const promoAgeVerify = (ageVerified: boolean) => ({
  type: actionTypes.PROMO_AGE_VERIFY,
  ageVerified,
})

export const promoResetGoustoOnDemandFlow = () => (dispatch: Dispatch<any>) => {
  dispatch(basketPromoCodeChange(''))
  dispatch(promoCurrentSet(''))
  dispatch(trackUTMAndPromoCode(clickCloseGodFailureHomepage))
  dispatch(promoToggleModalVisibility(false))
}
