import { useCallback } from 'react'

import moment from 'moment'
import { useSelector } from 'react-redux'

import { DeliveryPoint } from '@library/api-postcode-lookup'

import { fetchDeliveryDays } from 'apis/deliveries'
import { useGet2DD10PMCutOff } from 'hooks/useGet2DD10PMCutOff'
import { useGetAAATestEnabled } from 'hooks/useGetAAATestEnabled'
import { getBasketDate } from 'selectors/basket'
import {
  getAvailableDeliveryDays,
  getDeliveryTariffId,
  transformDaySlotLeadTimesToMockSlots,
} from 'utils/deliveries'
import logger from 'utils/logger'

export const useCheckCanDeliver = (
  setPostcodeError: (error: string) => void,
  setDeliveryPoints: (points: DeliveryPoint[]) => void,
  postcode: string,
) => {
  const deliveryDate = useSelector(getBasketDate)
  const menuCutoffUntil = useSelector((state) => (state as any).menuCutoffUntil)
  const deliveryTariffId = useSelector((state) => getDeliveryTariffId((state as any).user))
  // https://gousto.atlassian.net/browse/TG-9075 : check AAA test on Optimisely but do nothing with it
  // This will be removed after the test is finished
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isAAATestEnabled] = useGetAAATestEnabled()
  const [isTwoDaysDelivery10PMCutOffEnabled, twoDaysDelivery10PMCutOffVariationValue] =
    useGet2DD10PMCutOff()

  const checkCanDeliver = useCallback(async () => {
    if (!deliveryDate) {
      setPostcodeError("Sorry, we don't deliver to this postcode yet")
      setDeliveryPoints([])

      return
    }

    const cutOfFrom = moment().startOf('day').toISOString()
    const menuCutoffUntilFallback = moment().startOf('day').add(30, 'days').toISOString()
    const cutOfUntil = menuCutoffUntil || menuCutoffUntilFallback

    try {
      let { data: days } = await fetchDeliveryDays(
        null,
        cutOfFrom,
        cutOfUntil,
        deliveryTariffId,
        postcode,
        undefined, // userId is undefined
        isTwoDaysDelivery10PMCutOffEnabled ? twoDaysDelivery10PMCutOffVariationValue : undefined,
      )

      days = transformDaySlotLeadTimesToMockSlots(days)

      const availableDeliveryDays = getAvailableDeliveryDays(days)

      if (
        availableDeliveryDays?.[deliveryDate] &&
        !availableDeliveryDays[deliveryDate].alternateDeliveryDay
      ) {
        setPostcodeError('')

        return
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.error(error)
    }

    setPostcodeError("Sorry, we don't deliver to this postcode yet")
    setDeliveryPoints([])
  }, [
    deliveryDate,
    deliveryTariffId,
    isTwoDaysDelivery10PMCutOffEnabled,
    menuCutoffUntil,
    postcode,
    setDeliveryPoints,
    setPostcodeError,
    twoDaysDelivery10PMCutOffVariationValue,
  ])

  return { checkCanDeliver }
}
