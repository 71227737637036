import React from 'react'

import { useDecision } from '@optimizely/react-sdk'
import { useDispatch } from 'react-redux'

import redirectActions from 'actions/redirect'
import { client as clientRoutes } from 'config/routes'
import { useBasket } from 'routes/Menu/domains/basket'
import { useShouldShowDeliveryDetailsPage } from 'routes/Menu/hooks/useShouldShowDeliveryDetailsPage'

import { Menu } from './Menu'
import { menuOverlayClick } from './actions/menuOverlayClick'

const MenuWrapper = (ownProps: any) => {
  const dispatch = useDispatch()
  const { addRecipe, removeRecipe, date } = useBasket()
  /**
   * Experiment to test allocation for Oprimizely FX implementation
   * Cleanup ticket - https://gousto.atlassian.net/browse/FEF-1188
   */
  useDecision('fef_ofx_menu_test')

  const shouldShowDeliveryDetailsPage = useShouldShowDeliveryDetailsPage()
  const {
    params: { orderId },
  } = ownProps

  const onOverlayClick = React.useCallback(() => {
    dispatch(menuOverlayClick(removeRecipe))
  }, [dispatch, removeRecipe])

  if (shouldShowDeliveryDetailsPage && !orderId && !date) {
    dispatch(redirectActions.replace(clientRoutes.deliveryDetails))

    return null
  }

  return (
    <Menu
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...ownProps}
      addRecipeToBasket={addRecipe}
      onOverlayClick={onOverlayClick}
    />
  )
}

export { MenuWrapper }
