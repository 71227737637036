import { useDecision } from '@optimizely/react-sdk'

import { useAuth } from 'routes/Menu/domains/auth'

type TwoDaysDelivery10PMCutOffKey = 'c' | 't1' | 't2'
type TwoDaysDelivery10PMCutOffValue = 'cohort_a' | 'cohort_b' | 'cohort_c'

export function useGet2DD10PMCutOff(): [boolean, TwoDaysDelivery10PMCutOffValue] {
  const mapVariationKeyToValue: Record<
    TwoDaysDelivery10PMCutOffKey,
    TwoDaysDelivery10PMCutOffValue
  > = {
    c: 'cohort_a',
    t1: 'cohort_b',
    t2: 'cohort_c',
  }

  const { isAuthenticated } = useAuth()
  const [decision] = useDecision('beetroots_ofx_2dd_10pm_cutoff')

  const variationKey = decision.variationKey as TwoDaysDelivery10PMCutOffKey

  const isEnabled = ['t1', 't2'].includes(variationKey) && !isAuthenticated

  const value = mapVariationKeyToValue[variationKey]

  return [isEnabled, value]
}
