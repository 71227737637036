import React, { useEffect, useMemo } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { useDecision } from '@optimizely/react-sdk'
import { generateHref } from 'Helmet/GoustoHelmet'
import Helmet from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'

import { Homepage } from '@features/homepage'

import { homeGetStarted } from 'actions/home'
import { RibbonTriggerContainer } from 'components/RibbonTrigger'
import routesConfig from 'config/routes'

import { useGetCtaProps } from './Cta/ctaHooks'
import {
  useCanShowPromoBanner,
  useGetPromoBannerDispatchedActions,
} from './PromoBanner/promoBannerHooks'
import { getRecipesFromAllRecipesCollection } from './getRecipesFromAllRecipesCollection'
import { knownVariants, defaultVariant, seo } from './homeConfig'
import { useGetMenuData, usePromocodeData } from './homeHooks'

const getHelmetLink = (variant: string) => {
  const {
    client: { home },
  } = routesConfig
  const knownVariant = knownVariants.includes(variant) ? variant : defaultVariant
  const link = knownVariant !== 'default' ? [{ rel: 'canonical', href: generateHref(home) }] : []

  return link
}

type HomePageProps = { location: { query: { variant: string } } }

export const HomePageContent = ({ location }: HomePageProps) => {
  const dispatch = useDispatch()

  const { dispatchRedirectLoggedInUser, dispatchMenuFetchData } = useGetMenuData()
  /**
   * Temporary experiment to test allocation for Optimizely FX implementation
   */
  useDecision('fef_ofx_homepage_test')

  dispatchRedirectLoggedInUser()

  useEffect(() => {
    const prefetchTimer = setTimeout(() => {
      dispatchMenuFetchData()
      clearTimeout(prefetchTimer)
    }, 500)

    return () => {
      clearTimeout(prefetchTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const link = useMemo(() => getHelmetLink(location.query.variant), [location.query.variant])
  const { dispatchApplyPromoCodeAndShowModal, dispatchRedirect, dispatchTrackUTMAndPromoCode } =
    useGetPromoBannerDispatchedActions()

  const canShowPromoBanner = useCanShowPromoBanner()
  const applyDiscount = () => {
    dispatchApplyPromoCodeAndShowModal()
    dispatchRedirect()
    dispatchTrackUTMAndPromoCode()
  }

  const { ctaText, ctaUri } = useGetCtaProps()
  const { defaultBannerText } = usePromocodeData()

  const handleSignupCTAClick = () => {
    datadogRum.addAction('start_onboarding')
    dispatch(homeGetStarted(ctaUri))
  }

  const homeCarouselRecipes = useSelector(getRecipesFromAllRecipesCollection)
  const recipesCarousel = homeCarouselRecipes.toJS()

  return (
    <>
      <Helmet
        title={seo.title}
        meta={seo.meta}
        link={link}
        style={[
          {
            cssText: `
              #react-root {
                height: 100%;
              }
            `,
          },
        ]}
      />

      <Homepage
        applyDiscount={applyDiscount}
        isPromoBannerVisible={canShowPromoBanner}
        promoBannerText={defaultBannerText}
        signupCTA={ctaText}
        onSignupCTAClick={handleSignupCTAClick}
        recipes={recipesCarousel}
      />

      <RibbonTriggerContainer name="home-page" />
    </>
  )
}
