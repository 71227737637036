import Immutable from 'immutable'
import { Action } from 'redux'

import { actionTypes } from 'actions/actionTypes'

export type PromoAction = Action<string> &
  Partial<{
    code: string
    promo: any
    errorText: string
    ageVerified: boolean
    visible: boolean
  }>

export const promosReducers = {
  promoCurrent: (state = '', action: PromoAction) => {
    switch (action.type) {
      case actionTypes.PROMO_SET:
        return action.code
      default:
        return state
    }
  },
  promoStore: (state = Immutable.Map({}), action: PromoAction) => {
    switch (action.type) {
      case actionTypes.PROMO_RECEIVE:
        return state.set(action.promo.code, Immutable.fromJS(action.promo))

      case actionTypes.PROMO_STORE_SAVE_ERROR:
        return state.set(action.code as string, Immutable.fromJS({ errorText: action.errorText }))

      default:
        return state
    }
  },
  promoAgeVerified: (state = false, action: PromoAction) => {
    switch (action.type) {
      case actionTypes.PROMO_AGE_VERIFY:
        return action.ageVerified

      default:
        return state
    }
  },
  promoModalVisible: (state = false, action: PromoAction) => {
    switch (action.type) {
      case actionTypes.PROMO_MODAL_VISIBILITY_CHANGE:
        return !!action.visible

      default:
        return state
    }
  },
}
