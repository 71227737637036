import { endpoint } from '@library/endpoint'

import { GoustoHeaders } from 'apis/headers'
import routes from 'config/routes'
import { canUseWindow } from 'utils/browserEnvironment'
import { fetch } from 'utils/fetch'
import { getFirstPartPostcode } from 'utils/format'

export function fetchDeliveryDays(
  accessToken,
  cutoffDatetimeFrom,
  menuCutoffUntil,
  deliveryTariffId,
  postcode,
  authUserId = undefined,
  laterCutoffExperiment,
) {
  const reqData = {
    'filters[cutoff_datetime_from]': cutoffDatetimeFrom,
    'filters[cutoff_datetime_until]': menuCutoffUntil,
    // this parameter can be removed once the flag has been removed from the backend
    ndd: 'true',
    delivery_tariff_id: deliveryTariffId,
    sort: 'date',
    direction: 'asc',
  }

  if (postcode && postcode.length >= 5) {
    reqData.postcode = getFirstPartPostcode(postcode)
  }

  if (laterCutoffExperiment) {
    reqData.later_cutoff_experiment = laterCutoffExperiment
  }

  const testEmailAddress = canUseWindow()
    ? window.sessionStorage.getItem('test_email_address') // TODO: use SessionStorageKeys.GoustoTestEmailAddressKey after fixing circular dependency
    : null

  const headers = {
    // authUserId header can be removed once NI slots are visible to all users
    ...(authUserId ? { 'x-gousto-user-id': authUserId } : {}),
    ...(testEmailAddress ? { [GoustoHeaders.XGoustoTestEmailAddress]: testEmailAddress } : {}),
  }

  return fetch(
    accessToken,
    `${endpoint('deliveries')}${routes.deliveries.days}`,
    reqData,
    'GET',
    undefined,
    headers,
  )
}

export function fetchDeliveryConsignment(accessToken, orderId) {
  const reqData = {
    'filters[order_id]': orderId,
  }

  const url = `${endpoint('deliveries')}${routes.deliveries.consignments}`

  return fetch(accessToken, url, reqData, 'GET')
}
