import React from 'react'

import { Box, Heading5, Body1, Body2, withCitrusTheme } from '@gousto-internal/citrus-react'
import moment from 'moment'

const DeliveryDateSlotTextBox = withCitrusTheme(Box, () => ({
  textWrap: 'nowrap',
}))

export const DeliveryDateSlot = ({
  slotLabel,
  tempDate,
}: {
  slotLabel: string
  tempDate: string
}) => (
  <Box data-testid="deliveryDateSlot" paddingBottom={[2, 0]}>
    <DeliveryDateSlotTextBox>
      <Heading5>Date and Time</Heading5>
      <Body1>{moment(tempDate).format('dddd DD MMM')}</Body1>
      <Body2>{slotLabel}</Body2>
    </DeliveryDateSlotTextBox>
  </Box>
)
