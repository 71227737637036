import React from 'react'

import {
  AlignItems,
  Body1,
  Box,
  Color,
  Display,
  Heading5,
  Icon,
  IconVariant,
  JustifyContent,
  Space,
  Text,
  useModal,
} from '@gousto-internal/citrus-react'
import { useSelector } from 'react-redux'

import { DELIVERY_BOX_SIZE_MODAL_NAME } from 'routes/DeliveryDetails/constants'
import { getNumPortions } from 'selectors/basket'

export const DeliveryBoxSize = () => {
  const boxSize = useSelector(getNumPortions)

  const { openModal } = useModal()

  return (
    <Box paddingV={[6, 12]}>
      <Box display="flex" justifyContent={JustifyContent.SpaceBetween} width="100%">
        <Heading5>Box Size</Heading5>
        <Box
          display={Display.Flex}
          alignItems={AlignItems.Center}
          role="button"
          aria-label="Edit box size"
          onClick={() => openModal(DELIVERY_BOX_SIZE_MODAL_NAME)}
          data-testid="box-size-edit-button"
        >
          <Icon name="edit" variant={IconVariant.Actionable} size={5} />
          <Space size={1} direction="horizontal" />
          <Text size={1} color={Color.Secondary_400}>
            Edit
          </Text>
        </Box>
      </Box>
      <Body1>{boxSize}-person box</Body1>
    </Box>
  )
}
