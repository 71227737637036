import React, { useEffect, useState } from 'react'

import {
  Heading4,
  Modal,
  ModalBody,
  ModalButton,
  ModalClose,
  ModalFooter,
  ModalHeader,
  useModal,
} from '@gousto-internal/citrus-react'
import { PortionSizeSelector } from 'PortionSizeSelector/PortionSizeSelector'
import { useDispatch, useSelector } from 'react-redux'

import { basketNumPortionChange } from 'actions/basket'
import { availablePortions, PortionSizes } from 'config/portionSizes'
import { DELIVERY_BOX_SIZE_MODAL_NAME } from 'routes/DeliveryDetails/constants'
import { getNumPortions } from 'selectors/basket'

export const DeliveryBoxSizeModal = () => {
  const { closeCurrentModal } = useModal()
  const dispatch = useDispatch()
  const boxSize = useSelector(getNumPortions)

  const [savedBoxSize, setSavedBoxSize] = useState<PortionSizes>(boxSize)
  const [selectedBoxSize, setSelectedBoxSize] = useState<PortionSizes>(boxSize)

  useEffect(() => {
    setSavedBoxSize(boxSize)
  }, [boxSize])

  const handleBoxSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newBoxSize = Number(event.target.value) as PortionSizes
    setSelectedBoxSize(newBoxSize)
  }

  const onConfirm = () => {
    if (selectedBoxSize !== savedBoxSize) {
      dispatch(basketNumPortionChange(selectedBoxSize, 'delivery_details'))
    }
    closeCurrentModal()
  }

  return (
    <Modal name={DELIVERY_BOX_SIZE_MODAL_NAME} onRequestClose={() => closeCurrentModal()}>
      <ModalClose onClose={() => closeCurrentModal()} />
      <ModalHeader>
        <Heading4>Edit box size</Heading4>
      </ModalHeader>
      <ModalBody>
        <PortionSizeSelector
          portionSize={selectedBoxSize}
          availablePortionSizes={availablePortions as PortionSizes[]}
          onChange={(e) => handleBoxSizeChange(e)}
        />
      </ModalBody>
      <ModalFooter>
        <ModalButton
          aria-label="Confirm box size"
          onClick={onConfirm}
          style={{ padding: '16px' }}
          width="100%"
        >
          Confirm box size
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}
