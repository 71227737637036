import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  JustifyContent,
  Icon,
  Space,
  Body2,
  IconVariant,
} from '@gousto-internal/citrus-react'
import Link from 'Link'
import classnames from 'classnames'

import { useAuth } from 'routes/Menu/domains/auth'
import { useShouldShowDeliveryDetailsPage } from 'routes/Menu/hooks/useShouldShowDeliveryDetailsPage'

import { MenuDateRange } from '../MenuDateRange'
import { MenuDeliveryDate } from '../MenuDateRange/MenuDeliveryDate'
import { MenuPreferences } from '../MenuPreferences'

import css from './SubHeader.css'

type SubHeaderProps = {
  fromJoin?: boolean
}

export const SubHeader = ({ fromJoin = false }: SubHeaderProps) => {
  const { isAuthenticated } = useAuth()
  const shouldShowDeliveryDetailsPage = useShouldShowDeliveryDetailsPage()

  return (
    <div className={classnames(css[fromJoin ? 'subHeaderSimple' : 'subHeader'])}>
      <div className={css.subHeaderContent}>
        <div className={css.filter}>
          {shouldShowDeliveryDetailsPage ? (
            <Box
              display={Display.Flex}
              alignItems={AlignItems.Center}
              justifyContent={JustifyContent.Center}
              data-testid="deliveryDetailsBackButton-desktop"
            >
              <Link to="/delivery-details">
                <Box
                  display={Display.Flex}
                  alignItems={AlignItems.Center}
                  justifyContent={JustifyContent.Center}
                >
                  <Icon name="arrow_back" variant={IconVariant.Actionable} />
                  <Body2 color={Color.Secondary_400}>Back</Body2>
                </Box>
              </Link>
              <Space size={4} direction="horizontal" />

              <MenuDeliveryDate variant="desktop" />
            </Box>
          ) : (
            <MenuDateRange variant="desktop" />
          )}

          <div className={css.filterRight}>
            <div className={css.filterSection}>
              {isAuthenticated && <MenuPreferences variant="desktop" />}
            </div>
          </div>
        </div>
      </div>
      <div className={css.mobile}>
        <Box
          alignItems={AlignItems.Center}
          bg={Color.White}
          display={Display.Flex}
          justifyContent={JustifyContent.SpaceBetween}
          paddingH={3}
          paddingV={2}
        >
          {shouldShowDeliveryDetailsPage ? (
            <Box
              display={Display.Flex}
              alignItems={AlignItems.Center}
              justifyContent={JustifyContent.Center}
              data-testid="deliveryDetailsBackButton-mobile"
            >
              <Link to="/delivery-details" className={css.icon}>
                <Icon name="arrow_back" variant={IconVariant.Actionable} />
              </Link>
              <Space size={1} direction="horizontal" />

              <MenuDeliveryDate variant="mobile" />
            </Box>
          ) : (
            <MenuDateRange variant="mobile" />
          )}

          {isAuthenticated && <MenuPreferences variant="mobile" />}
        </Box>
      </div>
    </div>
  )
}
